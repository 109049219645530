import React, { useState, useEffect } from 'react';

import { countries } from 'country-flags-svg';

import UseModal from 'hooks/use-modal';
import Modal from '@core/components/Modal/Modal';

import classes from './CartCountrySelect.module.scss';
import { MOBILE_SLIDE_UP } from '@core/constants/ModalTypes';

const CartCountrySelect = ({
	mobileDevice,
	selectedCountry,
	setSelectedCountry,
	input,
	validation,
	register,
	errors,
	setValue,
	className,
	horizontal = false
}) => {
	const { show: openList, destroyModal, showModal } = UseModal();
	const [inputValue, setInputValue] = useState('');
	const focusHandler = () => {
		showModal();
	};
	const blurHandler = () => {
		!mobileDevice && destroyModal();
		selectedCountry ? setInputValue(selectedCountry.name) : setInputValue('');
	};
	const itemSelectHandler = (item) => {
		setSelectedCountry(item);
		setInputValue(item.name);
		setValue('country', item.name);
		destroyModal();
	};
	const getCountryFiltered = () => {
		return inputValue
			? countries.filter((item) =>
					item.name.toLowerCase().startsWith(inputValue.trim().toLowerCase())
			  )
			: countries;
	};
	// init default
	useEffect(() => {
		if (selectedCountry && typeof selectedCountry === 'string') {
			const defaultCountry = countries.find((country) => country.iso2 === selectedCountry);
			setSelectedCountry(defaultCountry);
			setInputValue(defaultCountry.name);
			setValue('country', defaultCountry.name);
		}
	}, []);
	// handle input change on autofill
	useEffect(() => {
		if (!openList && inputValue) {
			const newCountry = countries.find(
				(country) => country.name.toLowerCase() === inputValue.toLowerCase()
			);
			if (newCountry) {
				setSelectedCountry(newCountry);
				setInputValue(newCountry.name);
				setValue('country', newCountry.name);
			} else {
				setSelectedCountry({});
				setInputValue('');
				setValue('country', '');
			}
		}
	}, [inputValue]);

	return (
		<>
			<div
				className={`${horizontal ? 'row d-flex flex-row' : 'd-flex flex-column'} ${
					classes['country-select']
				} ${className ?? ''}`}>
				<label
					htmlFor={input.id || 'country'}
					className={`${horizontal ? 'col-12 col-xl-4' : ''} my-2`}>
					{input.label || 'Country'}
				</label>
				<div
					className={`${classes['country-select__input']} ${
						(errors && errors[input.name] && classes['country-select__input__invalid']) || ''
					} d-flex flex-row align-items-center gap-2 ${horizontal ? 'col-12 col-xl-8' : ''}`}>
					<img src={selectedCountry?.flag} alt={selectedCountry?.name} />
					<input
						value={inputValue}
						onClick={focusHandler}
						{...input}
						{...register(input.name, validation)}
						onChange={(e) => setInputValue(e.target.value)}
						onBlur={blurHandler}
					/>
				</div>
				{!mobileDevice ? (
					openList && (
						<div
							className={`${classes['countries-wrapper']} ${
								horizontal ? `col-12 col-xl-8 ${classes['countries-wrapper__horizontal']} ` : ''
							}`}>
							<div className={`${classes['countries-wrapper__list']}`}>
								{getCountryFiltered()?.map((item) => {
									return (
										<button
											key={item.iso2}
											// onClickCapture={() => itemSelectHandler(item)}
											onMouseDown={() => itemSelectHandler(item)}
											className={`${classes['list-item']} d-flex flex-row align-items-center w-100 btn gap-2`}>
											<img src={item.flag} alt={item.name} />
											<p type="text">{item.name}</p>
										</button>
									);
								})}
							</div>
						</div>
					)
				) : (
					<Modal
						title="Select Country"
						type={MOBILE_SLIDE_UP}
						show={openList}
						closeHandler={destroyModal}>
						<div className={`${classes['country-select-modal']} d-flex flex-column gap-2`}>
							<input
								type="text"
								autoComplete="off"
								onChange={(e) => setInputValue(e.target.value)}
								value={inputValue}
								required
							/>
							<div className={`${classes['countries-wrapper-mobile']}`}>
								<div className={`${classes['countries-wrapper-mobile-list']}`}>
									{getCountryFiltered()?.map((item) => {
										return (
											<button
												key={item.iso2}
												// onClickCapture={() => itemSelectHandler(item)}
												onMouseDown={() => itemSelectHandler(item)}
												className={`${classes['list-item']} d-flex flex-row align-items-center w-100 btn gap-2`}>
												<img src={item.flag} alt={item.name} lazy="true" />
												<p type="text">{item.name}</p>
											</button>
										);
									})}
								</div>
							</div>
						</div>
					</Modal>
				)}
			</div>
		</>
	);
};

export default CartCountrySelect;
