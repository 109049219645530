// ** React
import React, { useLayoutEffect, useState } from 'react';
import { useQuery } from 'react-query';

import { userMetadataService } from 'services/auth';
import { getAccessToken, isUserLoggedIn } from 'utils/authentication';
import { companyInit } from 'services/company';
import { CompanyMembershipStatus } from 'constants/company';

import { getCurrentRouteConfig } from 'utils/routerUtils';

import { useRouter } from 'next/router';

// ** Redux
import { useDispatch } from 'react-redux';
import { setHasSubscription, setAvailableTrialDays } from 'store/User/auth-slice';

// ** TITLE DEPENDENCY
import Title from '@core/layouts/title/Title';

// ** Navigation Dependency
import DesktopnMenu from '@core/layouts/desktopmenu/DesktopnMenu';
import MobileMenu from '@core/layouts/mobilemenu/MobileMenu';
import CompanyDashboard from '@core/layouts/companydashboard/CompanyDashboard';
import AppFooter from '@core/components/footer/AppFooter';
import MyCompanyTitle from 'components/pages/company/dashboard/title/MyCompanyTitle';
import Spinner from '@core/components/spinner/Spinner';


import useWindowSize from 'hooks/use-windowSize';

import classes from './LayoutWrapper.module.scss';




const LayoutWrapper = ({ children }) => {
	const [layoutData, setLayout] = useState({ layout: '', meta: {} });
	const router = useRouter();
	const dispatch = useDispatch();
	const { data } = useQuery(['user-metadata', getAccessToken()], userMetadataService, {
		refetchInterval: 60000 * 15,
		onSuccess: (data) => {
			dispatch(setHasSubscription(data?.data.has_active_subscription));
			dispatch(setAvailableTrialDays(data?.data.available_trial_days));
		}
	});

	const { data: companyData, isLoading } = useQuery(
		['company-profile-init-menu', getAccessToken(), isUserLoggedIn()],
		companyInit.GET,
		{
			onSuccess: (resp) => {
				if (resp.data.company_membership === CompanyMembershipStatus.LACKING) {
					router.replace('/company');
				}
			},
			onError: (error) => {
				if (error.response.status === 428) {
					router.replace('/company/verification');
				}
			},
			enabled: !!layoutData.meta.isCompanyDashboard,
			cacheTime: 20 * 60 * 1000,
			taleTime: 20 * 60 * 1000,
		}
	);
	const { desktopDevice } = useWindowSize();

	useLayoutEffect(() => {
		if (router.isReady) {
			const routeConfig = getCurrentRouteConfig(router);
			setLayout(routeConfig);
		}
	}, [router.pathname, router.isReady]);

	return (
		<>
			{desktopDevice ? (
				<div className={`${classes['layout-container']}`}>
					<DesktopnMenu
						vipAccess={data?.data.has_vip_access}
						isB2B={layoutData.meta.isB2B}
						newNotificationCount={data?.data.new_notifications_count}
						hasSubscription={data?.data.has_active_subscription}
						avalaibleTrialDays={data?.data.available_trial_days}
					/>
					{layoutData.meta.isCompanyDashboard ? (
						<>
							{companyData && !isLoading && (<CompanyDashboard children={children} data={companyData.data} />)}
							{isLoading && (<div className={classes['loading']}><Spinner /></div>)}
						</>
					) : (
						<div
							className={`${classes['content-wrapper']} d-flex align-items-center justify-content-center`}>
							{children}
						</div>
					)}

					<AppFooter />
				</div>
			) : (
				<>
					{layoutData.meta.isCompanyDashboard && (
						<MyCompanyTitle
							accessible_items={companyData?.data?.accessible_items}
							company_membership={companyData?.data?.company_membership}
						/>
					)}
					<div className={`${classes['layout-container']}`}>
						<Title {...layoutData.meta.title} />
						<div className={`${classes['content-wrapper']}`}>{children}</div>
						<MobileMenu
							vipAccess={data?.data.has_vip_access}
							layout={layoutData.layout}
							isB2B={layoutData.meta.isB2B}
						/>
					</div>
				</>
			)}


		</>
	);
};

export default LayoutWrapper;
