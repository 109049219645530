import React from 'react';
import { useRouter } from 'next/router';

// ** custom components
import BackButton from '@core/components/backbutton/BackButton';
import BergerMenu from './CompanyBurgerMenu';

import { CompanyDashboardPages } from 'constants/company';

import classes from './MyCompanyTitle.module.scss';

const MyCompanyTitle = ({ accessible_items, company_membership }) => {
	const router = useRouter();
	function formatAndCapitalize(inputString) {
		// Replace underscores with spaces
		let formattedString = inputString.replace(/_/g, ' ');

		// Capitalize the first letter
		formattedString = formattedString.charAt(0).toUpperCase() + formattedString.slice(1);

		return formattedString;
	}

	return (
		<div
			className={`${classes['sub-page']} container d-flex flex-row justify-content-between d-xl-none my-3`}>
			<BackButton type="simple" />
			<h1 className="px-4">{formatAndCapitalize(CompanyDashboardPages.find(page => page.url === router.pathname).name)}</h1>
			<div>
				<BergerMenu
					accessible_items={accessible_items}
					company_membership={company_membership}
				/>
			</div>
		</div>
	);
};

export default MyCompanyTitle;
